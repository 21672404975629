<div id="divmini">
<div *ngIf="Isjitsienable">
  <div style="transform: translate(-1%, 15%)">
    <div style="width: 30%; float: left; height: 400px; overflow: auto">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <span (click)="minimise()">-</span>
        <div class="ml-0">
          <h4 class="mb-0" style="color: #1e50e2">Add-Participants</h4>
        </div>
        <div class="mr-0 pr-4" style="display: none">
          <img
            (click)="getContactListnew()"
            src="./assets/img/refresh.png"
            alt=""
            class="border p-2 rounded-circle"
            style="height: 32px"
          />
        </div>
      </div>
      <div class="clear border-top">
        <div *ngFor="let contact of contactList" class="">
          <div [ngClass]="{ grey: contact.actiontype === true }">
            <div id="grpid{{ contact.SNo }}" [disabled]="contact.actiontype">
              <div class="ss">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    border-bottom
                    pr-4
                    py-3
                    border-bottom
                  "
                >
                  <div class="ml-0">
                    {{ contact.RemoteUserFirstName }}({{ contact.phone }})
                  </div>
                  <div class="mr-0" *ngIf="!!contact.userId === true">
                    <a
                      href="javascript:void(0);"
                      class="d-inline-flex align-items-center border-btn"
                      (click)="intializegroupCall(contact)"
                    >
                      <i class="fa fa-plus mr-2"></i>
                      <span>Add</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="width: 70%; float: right">
  <div id="jitsi-iframe-new"></div>
</div>
</div>
<div *ngIf="Isjitsienable">
  <div class="item-center d-flex justify-content-between clear pt-3">
    <button
      class="btn btn-danger border-0 w-40 mx-auto"
      (click)="closeDialogaftercallreceive()"
    >
      <img height="15px" src="./assets/img/dialing-phone.png" alt="" />
    </button>
  </div>
</div>
<div *ngIf="!Isjitsienable">
  <div class="dialing">
    <h3 class="text-white text-center py-3"></h3>
    <div class="ripple-background">
      <div class="circle-1 small-1">
        <img
          class="h-100 w-100 position-absolute rounded-circle"
          src="./assets/img/boy.png"
          alt=""
        />
      </div>
    </div>
    <h2 class="text-white text-center font-weight-bold pt-2 pb-1">
      <div id="rcvcall">{{ name }}</div>
    </h2>
    <h6 class="text-white text-center pb-3">
      <i style="font-size: 100px" class="{{ callType }}"></i>
      <br />Incoming Call
    </h6>
    <div class="row mx-0 mb-3">
      <!-- <div class="col-md-4 col-3 d-flex align-items-center" >               
                    <img class="mr-0" height="30px" src="./assets/img/dialing-microphone.png" alt="">            
                </div> -->
      <div class="col-md-4 col-6 d-flex align-items-center">
        <button
          class="btn btn-success border-0 w-50 mx-auto"
          (click)="callaccept()"
        >
          <img
            height="15px"
            style="transform: rotate(-20deg)"
            src="./assets/img/dialing-phone.png"
            alt=""
          />
        </button>
      </div>
      <div class="col-md-4 col-6 d-flex align-items-center">
        <button
          class="btn btn-danger border-0 w-50 mx-auto"
          (click)="closeDialog()"
        >
          <img height="15px" src="./assets/img/dialing-phone.png" alt="" />
        </button>
      </div>
      <!-- <div class="col-md-4 col-3 d-flex align-items-center">
                    <img height="25px" class="ml-0" src="./assets/img/dialing-speaker.png" alt="">
                </div> -->
    </div>
    <div
      class="row mx-0 justify-content-center align-items-center"
      height="25px"
    >
      <!-- <img height="25px" class="mx-auto mt-5" src="./assets/img/dialing-video.png" alt=""> -->
    </div>
  </div>
</div>
