<div *ngIf="isreceived">
  <div style="transform: translate(-1%, 15%)">
    <div style="width: 30%; float: left; height: 400px; overflow: auto">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="ml-0">
          <h4 class="mb-0" style="color: #1e50e2">Add-Participants</h4>
        </div>
        <div class="mr-0 pr-4" style="display: none">
          <img
            (click)="getContactListnew()"
            src="./assets/img/refresh.png"
            alt=""
            class="border p-2 rounded-circle"
            style="height: 32px"
          />
        </div>
      </div>
      <div class="clear border-top">
        <div *ngFor="let contact of contactList" class="">
          <!-- <div [disabled]="contact.actiontype"> -->
          <div [ngClass]="{ grey: contact.actiontype === true }">
            <div id="grpid{{ contact.SNo }}">
              <div class="ss">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    border-bottom
                    pr-4
                    py-3
                    border-bottom
                  "
                >
                  <div class="ml-0">
                    {{ contact.RemoteUserFirstName }}({{ contact.phone }})
                  </div>
                  <div class="mr-0" *ngIf="contact.Issync === true">
                    <a
                      href="javascript:void(0);"
                      class="d-inline-flex align-items-center border-btn"
                      (click)="intializegroupCall(contact)"
                    >
                      <i class="fa fa-plus mr-2"></i>
                      <span>Add</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="width: 70%; float: right">
  <div id="jitsi-iframe"></div>
</div>

<div *ngIf="isreceived">
  <div class="item-center d-flex justify-content-between clear pt-3">
    <i
      (click)="executeCommand('toggleAudio')"
      class="fas fa-2x grey-color"
      [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'"
      aria-hidden="true"
      title="Mute / Unmute"
    ></i>
    <i
      (click)="executeCommand('hangup')"
      class="fas fa-phone-slash fa-2x red-color"
      aria-hidden="true"
      title="Leave"
    ></i>
    <i
      (click)="executeCommand('toggleVideo')"
      class="fas fa-2x grey-color"
      [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'"
      aria-hidden="true"
      title="Start / Stop camera"
    ></i>
    <i
      (click)="executeCommand('toggleShareScreen')"
      class="fas fa-film fa-2x grey-color"
      aria-hidden="true"
      title="Share your screen"
    ></i>
  </div>
</div>
<div id="dialsub" *ngIf="!isreceived">
  <div class="dialing">
    <h3 class="text-white text-center py-3">Outgoing Call</h3>
    <div class="ripple-background">
      <div class="circle-1 small-1">
        <img
          class="h-100 w-100 position-absolute rounded-circle"
          src="./assets/img/boy.png"
          alt=""
        />
      </div>
    </div>
    <h2 class="text-white text-center font-weight-bold pt-3 pb-1">
      <div id="dialname">{{ name }}</div>
    </h2>
    <h6 class="text-white text-center pb-5">
      <i style="font-size: 60px" class="{{ callType }}"></i> <br />Dialing
    </h6>
    <div class="row mx-0">
      <div class="col-md-4 col-6 d-flex align-items-center">
        <button
          class="btn btn-danger border-0 w-50 mx-auto"
          (click)="closeDialog()"
        >
          <img height="15px" src="./assets/img/dialing-phone.png" alt="" />
        </button>
      </div>
    </div>
    <div
      class="row mx-0 justify-content-center align-items-center"
      height="25px"
    >
      <!-- <img height="25px" class="mx-auto mt-5" src="./assets/img/dialing-video.png" alt=""> -->
    </div>
  </div>
</div>
