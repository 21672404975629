import { Component, OnInit, Input, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WebSocketService } from '../../services/web-socket.service'
import { CredentialService } from 'src/app/services/credential.service';
import { ChatService } from 'src/app/services/chat.service';
import { HttpResponse } from '@angular/common/http';
import { contactList } from 'src/app/dataModel/contactList.model';
import { ToastrService } from 'ngx-toastr';
declare var JitsiMeetExternalAPI: any;
import { Unsubscribable } from 'rxjs';



@Component({
  selector: 'app-dialing',
  templateUrl: './dialing.component.html',
  styleUrls: ['./dialing.component.scss'],
})



export class DialingComponent implements OnInit, AfterViewInit {


  domain: string = "web.cbcapps.com"; // For self hosted use your domain
  flag :boolean=true;
  room: any;
  options: any;
  api: any;
  user: any;
  contactList: contactList[] = [];
  // For Custom Controls
  callType: string = "fa fa-phone";
  isAudioMuted = false;
  isVideoMuted = true;
  iscalled: any = 0;
  name: string = 'Unknown';
  isreceived: boolean = false;
  WebSocketServiceSubs: any;


  constructor(
    private _chat: ChatService,
    private _credential: CredentialService,
    private router: Router,
    private WebSocketService: WebSocketService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialingComponent>

  ) {
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    if (localStorage.getItem("call_type") == "Video") {
      this.callType = "fa fa-video-camera";
    }
    this.name = localStorage.getItem('dialname');
    //document.getElementById('dialname').innerHTML=localStorage.getItem('dialname');
    setTimeout(() => {

      var checkstatus = localStorage.getItem('callInitiated');
      if (checkstatus != 'yes') {
        localStorage.removeItem('callInitiated');
        var datacall = JSON.parse(localStorage.getItem('getdialerdata'));

        if (datacall == null) {
          localStorage.removeItem('callInitiated');
          localStorage.removeItem('getdialerdata');
          this.dialog.closeAll();

        }
        else {
          let callrejectData = {
            "call_type": datacall.call_type,
            "receiver_id": datacall.receiver_id,
            "receiver_mobile_no": datacall.receiver_mobile_no,
            "receiver_username": datacall.receiver_username,
            "sender_userid": datacall.sender_userid,
            "sender_phone_no": datacall.sender_phone_no,
            "sender_username": datacall.sender_username,
            "user_type": "H",
            "status": "0",
            "parent_id": datacall.parent_id,
            "_id": datacall._id

          };

          localStorage.removeItem('getdialerdata');

          this.WebSocketService.emit('callDismiss', callrejectData);

          this.dialog.closeAll();
        }

      }

    }, 60000);

    this.getContactListnew();


    this.room = '111';//'bwb-bfqi-vmh'; // Set your room name
    this.user = {
      name: 'NA' // Set your username
    }
  }


  minimise() {

  }
  getContactListnew() {
    var contactdata = JSON.parse(localStorage.getItem(this._credential.credentials._id));
    if (contactdata && contactdata.length > 0) {
      // contactdata = contactdata.filter((contactElement) => {return contactElement.Issync === true}) // filter only registered contacts
      this.contactList = contactdata;

    }
    else {
      this._chat.getContactListnew(
        this._credential.credentials._id,
        this._credential.credentials.chatToken,
        "WEB")
        .subscribe((response: HttpResponse<any>) => {
          if (response.status == 200 ||
            response.status == 201 ||
            response.status == 202 ||
            response.status == 204) {
            let users = response.body.response_data;
            let counter = 0;
            var datacall = JSON.parse(localStorage.getItem('getdialerdata'));
            let chatContactList = [];
            users.length && users.map(user => {
              if (user) {
                if (user.firstName != this._credential.credentials.firstName) {
                  counter++;
                  chatContactList.push({
                    SNo: counter,
                    "RemoteUserFirstName": user.firstName,
                    RemoteUserOrGroupId: user._id,
                    RemoteUserLastName: user.lastName,
                    "chatType": "single",
                    msg: "",
                    RoomId: "",
                    RemoteUserOrGroupImage: "",
                    userId: user._id,
                    userName: user.userName,
                    phone: user.phone
                  });
                }
              }

              this.contactList = chatContactList;
            });



          } else {
            this.toastr.error(response.body.response_message);
          }
        }, error => {
          this.toastr.error(error.error.message);
        });
    }
  }


  intializegroupCall(user) {
    var datacall = JSON.parse(localStorage.getItem('getdialerdata'));
    this._chat.getJoinedList(
      this._credential.credentials._id,
      datacall.parent_id, this._credential.credentials.chatToken)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {
          ;
          var findrow = response.body.response_data.find(h => h._id === user.userId);
          if (findrow.isblock == "true") {
            this.toastr.error('User is blocked.');
            return;
          }
          if (findrow.isjoined == "true") {
            this.toastr.error('User already on call');
            return;
          }
          var data = user
          this.iscalled = 1;

          localStorage.setItem('jitsiInstialize', '-1');
          let callData = {
            "call_type": datacall.call_type,
            "receiver_id": user.userId,
            "receiver_mobile_no": user.phone,
            "receiver_username": user.userName,
            "sender_userid": this._credential.credentials._id,
            "sender_phone_no": this._credential.credentials.phone,
            "sender_username": this._credential.credentials.firstName + ' ' + this._credential.credentials.lastName,
            "user_type": "P",
            "status": "0",
            "parent_id": datacall.parent_id,
          };
          this.contactList[(user.SNo - 1)].actiontype = true;
          this.WebSocketService.emit('addParticipant', callData);

        } else {
          this.toastr.error(response.body.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });


  }


  ngAfterViewInit(): void {
    if(this.flag) {
      this.flag = false;
    }
    this.options = {
      roomName: this.room,
      width: 600,
      height: 400,
      configOverwrite: { prejoinPageEnabled: false, startWithVideoMuted: true },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        TOOLBAR_BUTTONS: ['microphone', 'camera', 'fullscreen', 'desktop',
          'tileview', 'profile', 'chat',
          'recording', 'livestreaming', 'screenshare',
          'videoquality', 'settings',
          'shortcuts',
          'help'],


      },

      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.user.name
      }
    }

    //----- this happen when call recejected by receiver --------
    this.WebSocketService.listen('callEnded').subscribe((data: any) => {
      ;
      if (this.iscalled === 0) {
        localStorage.removeItem('getdialerdata');
        localStorage.removeItem('callInitiated');
        this.dialog.closeAll();
      }

    })
    //----- this happen when call recejected by receiver --------
    this.WebSocketService.listen('callRejected').subscribe((data: any) => {
      ;
      // will not fire when add participants.      
      if (this.iscalled === 0) {
        localStorage.removeItem('getdialerdata');
        localStorage.removeItem('callInitiated');
        this.dialog.closeAll();
      }


    })
    //-- this will fire when call accepted by receiver -------------------
    this.WebSocketServiceSubs = this.WebSocketService.listen('callAccepted').subscribe((data: any) => {
      this.isreceived = true;
      let jitsiInstialize = parseInt(localStorage.getItem('jitsiInstialize'));

      if (jitsiInstialize != -1) {

        localStorage.setItem('callInitiated', 'yes');

        this.options.roomName = data.parent_id;
        //  this.options.userInfo.displayName=this.name;
        this.options.userInfo.displayName = data.receiver_username;


        if (data.call_type == "Video") {
          this.options.configOverwrite.startWithVideoMuted = false;
        }


        // var ifrm = document.createElement("iframe");
        // ifrm.setAttribute("src", `https://web.cbcapps.com/${data.parent_id}`);
        // ifrm.style.width = "640px";
        // ifrm.style.height = "480px";
        // var loadSubscribe =document.querySelector('#jitsi-iframe');
        // loadSubscribe.append(ifrm);
        // console.log('asaaaaaaaaaaaaaaaa',data.parent_id);
  
      
        this.api = new JitsiMeetExternalAPI(this.domain, this.options);
        // Event handlers
        this.api.addEventListeners({
          readyToClose: this.handleClose,
          participantLeft: this.handleParticipantLeft,
          participantJoined: this.handleParticipantJoined,
          videoConferenceJoined: this.handleVideoConferenceJoined,
          videoConferenceLeft: this.handleVideoConferenceLeft,
          audioMuteStatusChanged: this.handleMuteStatus,
          videoMuteStatusChanged: this.handleVideoStatus
        });
        this.api.executeCommand('subject', ' ');
        jitsiInstialize = jitsiInstialize + 1;
        localStorage.setItem('jitsiInstialize', jitsiInstialize.toString());
        var objIndex = this.contactList.findIndex((obj => obj.userId == data.sender_userid));
        if (objIndex >= 0) {
          this.contactList[objIndex].actiontype = true;
        }

      }
    })


  }

  closeDialogaftercallreceive() {

    var datacall = JSON.parse(localStorage.getItem('getdialerdata'));
    if (datacall == null) {
      localStorage.removeItem("callaccept");
      this.dialog.closeAll();

    }
    let callrejectData = {
      "call_type": datacall.call_type,
      "sender_userid": datacall.sender_userid,
      "sender_phone_no": datacall.sender_phone_no,
      "sender_username": datacall.sender_username,
      "user_type": "P",
      "status": "5",
      "parent_id": datacall.parent_id,
      "_id": datacall._id

    };
    localStorage.removeItem('callInitiated');

    localStorage.removeItem("getdialerdata");

    this.WebSocketService.emit('endCall', callrejectData);


    this.dialog.closeAll();
  }


  // this will close the dilaog box and fire emit of calldismissed when call cut by dialer---
  closeDialog() {
    var datacall = JSON.parse(localStorage.getItem('getdialerdata'));
    if (datacall == null) {
      localStorage.removeItem('callInitiated');
      localStorage.removeItem('getdialerdata');
      this.dialog.closeAll();

    }
    else {

      let callrejectData = {
        "call_type": datacall.call_type,
        "receiver_id": datacall.receiver_id,
        "receiver_mobile_no": datacall.receiver_mobile_no,
        "receiver_username": datacall.receiver_username,
        "sender_userid": datacall.sender_userid,
        "sender_phone_no": datacall.sender_phone_no,
        "sender_username": datacall.sender_username,
        "user_type": "H",
        "status": "0",
        "parent_id": datacall.parent_id,
        "_id": datacall._id

      };
      localStorage.removeItem('callInitiated');
      localStorage.removeItem('getdialerdata');

      this.WebSocketService.emit('callDismiss', callrejectData);
      this.dialog.closeAll();
    }

  }


  handleClose = () => {
    console.log("handleClose");
  }

  handleParticipantLeft = async (participant) => {

    const data = await this.getParticipants();
  }

  handleParticipantJoined = async (participant) => {

    const data = await this.getParticipants();
  }

  handleVideoConferenceJoined = async (participant) => {

    const data = await this.getParticipants();
  }

  handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    this.router.navigate(['/thank-you']);
  }

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
  }

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  }

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500)
    });
  }

  executeCommand(command: string) {
   this.api.executeCommand(command);;
    if (command == 'hangup') {
      // this.WebSocketService.listen('callAccepted').unsubscribe((data: any) => {
      // })
      console.log("hanguppppp");
      this.closeDialogaftercallreceive();
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }

  ngOnDestroy() {
    this.WebSocketServiceSubs.unsubscribe();
  }
}
