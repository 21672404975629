import { Component, OnInit, Input,AfterViewInit,Inject } from '@angular/core';
import {MatDialogConfig, MatDialogRef,MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WebSocketService } from '../../services/web-socket.service'
import { CredentialService } from 'src/app/services/credential.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ChatService } from 'src/app/services/chat.service';
import { HttpResponse } from '@angular/common/http';
import { contactList } from 'src/app/dataModel/contactList.model';
import { ToastrService } from 'ngx-toastr';
declare var JitsiMeetExternalAPI: any;


@Component({
  selector: 'app-receivingcall',
  templateUrl: './receivingcall.component.html',
  styleUrls: ['./receivingcall.component.scss']
})
  


  export class receivingcallComponent implements OnInit, AfterViewInit {

    domain: string = "web.cbcapps.com"; // For self hosted use your domain
    room: any;
    options: any;
    api: any;
    user: any;
    contactList: contactList[] = [];
    name: string='Unknown';
    isAudioMuted = false;
    isVideoMuted = false;
    Isjitsienable:boolean=false;
    callType: string = "fa fa-phone";
    constructor(
      private _utils: UtilsService,
      private _chat: ChatService,
      private _credential: CredentialService,
      private router: Router,
      private WebSocketService: WebSocketService,
          public dialog: MatDialog,
          private toastr: ToastrService,
          private dialogRef: MatDialogRef<receivingcallComponent>
  ) {
    dialogRef.disableClose = true;
  }

    ngOnInit(): void {

      this.name=localStorage.getItem('rcvcall');
         this.getContactListnew();
        this.room = '111'; // Set your room name
        this.user = {
            name: 'NA' // Set your username
        }

        var datacall=JSON.parse(localStorage.getItem('callaccept')); 
        if (datacall.call_type=="Video")
          {
            this.callType = "fa fa-video-camera";
          }

    }

    ngAfterViewInit(): void {
      this.options = {
        roomName: this.room,
        width: 600,
        height: 400,
        configOverwrite: { prejoinPageEnabled: false ,  startWithVideoMuted: true,RECENT_LIST_ENABLED: false},
        interfaceConfigOverwrite: {
            // overwrite interface properties
            TOOLBAR_BUTTONS: [ 'microphone', 'camera', 'fullscreen','desktop',
              'tileview', 'profile', 'chat',
              'recording', 'livestreaming','screenshare',
              'videoquality', 'settings',
              'shortcuts',
              'help' ],
          
            
        },
       
        parentNode: document.querySelector('#jitsi-iframe-new'),
        userInfo: {
            displayName: this.user.name
        }
    }
    //----- this happen when call recejected by receiver --------
    this.WebSocketService.listen('callEnded').subscribe((data: any) => {
        localStorage.removeItem('callaccept');
        this.dialog.closeAll();
    
    })  
        this.WebSocketService.listen('callDismissed').subscribe((data: any) => {
            localStorage.removeItem("callaccept");
            this.dialog.closeAll();
           
    })

       
    }
    closeDialogaftercallreceive() {
      var datacall=JSON.parse(localStorage.getItem('callaccept'));
      if (datacall==null)
      { 
          localStorage.removeItem("callaccept");
        this.dialog.closeAll();
      }
      let callrejectData = {
          "call_type": "Audio",
          "sender_userid": datacall.receiver_id,
          "sender_phone_no": datacall.receiver_mobile_no,
          "sender_username": datacall.receiver_username,
          "user_type": "P",
          "status": "5",
          "parent_id":datacall.parent_id,
          "_id":datacall._id
        };
        localStorage.removeItem("callaccept");
       this.WebSocketService.emit('endCall', callrejectData);
      this.dialog.closeAll();
     }

closeDialog() {
 var datacall=JSON.parse(localStorage.getItem('callaccept'));
 if (datacall==null)
 {       
     localStorage.removeItem("callaccept");
   this.dialog.closeAll();    
 }
 let callrejectData = {
     "call_type": "Audio",
     "receiver_id": datacall.sender_userid,
     "receiver_mobile_no":datacall.sender_phone_no,
     "receiver_username": datacall.sender_username,
     "sender_userid": datacall.receiver_id,
     "sender_phone_no": datacall.receiver_mobile_no,
     "sender_username": datacall.receiver_username,
     "user_type": "P",
     "status": "4",
     "parent_id":datacall.parent_id,
     "_id":datacall._id,
     "groupcall":false

   };
   localStorage.removeItem("callaccept");
 this.WebSocketService.emit('callReject', callrejectData);
 // this.WebSocketService.emit('endCall', callrejectData);
 this.dialog.closeAll();
}
minimise()
{
  alert('1');
 // document.getElementById('divmini').style.marginLeft="100px";
  //document.getElementById('divmini').style.width="100px";
  const matDialogConfig = new MatDialogConfig();
  matDialogConfig.width = "100px";
  matDialogConfig.height = "180px";
  this.dialog.open(receivingcallComponent, matDialogConfig);
  
 
}
getContactListnew() {
 var contactdata  = JSON.parse(localStorage.getItem(this._credential.credentials._id));
 if (contactdata&&contactdata.length>0)
 {
  // contactdata = contactdata.filter((contactElement) => {return !!contactElement.userId === true}) // filter only registered contacts
  // this.contactList = contactdata.sort(this._utils.dynamicSort("RemoteUserFirstName"));
  this.contactList = contactdata;
   var datacall=JSON.parse(localStorage.getItem('callaccept')); 
 }
 else
 {
 this._chat.getContactListnew(
   this._credential.credentials._id,
   this._credential.credentials.chatToken,
   "WEB")
   .subscribe((response: HttpResponse<any>) => {
     if (response.status == 200 ||
       response.status == 201 ||
       response.status == 202 ||
       response.status == 204) {
       let users = response.body.response_data;
let counter= 0;
var datacall=JSON.parse(localStorage.getItem('getdialerdata'));
       let chatContactList = [];
       users.length && users.map(user => {
         if (user) {  
           if (user.firstName!=this._credential.credentials.firstName )
           {        
             counter++;
           chatContactList.push({
             SNo:counter,
             "RemoteUserFirstName": user.firstName,
             RemoteUserOrGroupId: user._id,
             RemoteUserLastName: user.lastName,
             "chatType": "single",
             msg: "",
             RoomId: "",
             RemoteUserOrGroupImage: "",
             userId: user._id,
             userName: user.userName,
             phone: user.phone
           });
         }
       }

        
       });

       this.contactList = chatContactList;
      
     } else {
       this.toastr.error(response.body.response_message);
     }
   }, error => {
     this.toastr.error(error.error.message);
   });
}
}
intializegroupCall(user) {
 var datacall=JSON.parse(localStorage.getItem('callaccept'));
  this._chat.getJoinedList(
    this._credential.credentials._id,
    datacall.parent_id, this._credential.credentials.chatToken)
    .subscribe((response: HttpResponse<any>) => {
      if (response.status == 200 ||
        response.status == 201 ||
        response.status == 202 ||
        response.status == 204) {
          ;
        var findrow = response.body.response_data.find(h => h._id === user.userId);
        if (findrow.isblock=="true")
        {
        this.toastr.error('User is blocked.');
        return;
        }
if (findrow.isjoined=="true")
{
this.toastr.error('User already on call');
return;
}
let callData = {
  "call_type":  datacall.call_type,
  "receiver_id": user.userId,
  "receiver_mobile_no": user.phone,
  "receiver_username": user.userName,
  "sender_userid": this._credential.credentials._id,
  "sender_phone_no":  this._credential.credentials.phone,
  "sender_username": this._credential.credentials.firstName + ' ' + this._credential.credentials.lastName,
  "user_type": "P",
  "status": "0",
  "parent_id":datacall.parent_id,
  };
  document.getElementById('grpid'+user.SNo).style.background="gainsboro";
  this.contactList[(user.SNo-1)].actiontype = true;
  this.WebSocketService.emit('addParticipant', callData);  
       
      } else {
        this.toastr.error(response.body.response_message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    });

}

callaccept()
{

  this.Isjitsienable=true;

  var datacall=JSON.parse(localStorage.getItem('callaccept')); 
  this.options.roomName=datacall.parent_id;
  // this.options.userInfo.displayName=this.name;
  this.options.userInfo.displayName = datacall.receiver_username;

  


if (datacall.call_type=="Video")
{
this.options.configOverwrite.startWithVideoMuted= false;

}
this.api = new JitsiMeetExternalAPI(this.domain, this.options);

// Event handlers
this.api.addEventListeners({
  readyToClose: this.handleClose,
  participantLeft: this.handleParticipantLeft,
  participantJoined: this.handleParticipantJoined,
  videoConferenceJoined: this.handleVideoConferenceJoined,
  videoConferenceLeft: this.handleVideoConferenceLeft,
  audioMuteStatusChanged: this.handleMuteStatus,
  videoMuteStatusChanged: this.handleVideoStatus
});

this.api.executeCommand('subject', ' ');

// var ifrm = document.createElement("iframe");
// ifrm.setAttribute("src", `https://web.cbcapps.com/${datacall.parent_id}`);
// ifrm.style.width = "640px";
// ifrm.style.height = "480px";
// var loadSubscribe =document.querySelector('#jitsi-iframe-new');
// loadSubscribe.append(ifrm);



 let callData = {
     "call_type": datacall.call_type,
     "receiver_id": datacall.sender_userid,
     "receiver_mobile_no":datacall.sender_phone_no,
     "receiver_username": datacall.sender_username,
     "sender_userid": datacall.receiver_id,
     "sender_phone_no": datacall.receiver_mobile_no,
     "sender_username": datacall.receiver_username,
     "user_type": "P",
     "status": "3",
     "parent_id":datacall.parent_id,
     "_id":datacall._id,
     "groupcall":false

   };

 this.WebSocketService.emit('callAccept', callData);
   var contactdata  = this.contactList;
   var objIndex = contactdata.findIndex((obj => obj.userId == datacall.sender_userid));
   if(objIndex>=0)
   {
   this.contactList[objIndex].actiontype = true;
   }
  
}



 handleClose = () => {
   console.log("handleClose");
}

handleParticipantLeft = async (participant) => {
   console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
   const data = await this.getParticipants();
}

handleParticipantJoined = async (participant) => {
   console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
   const data = await this.getParticipants();
}

handleVideoConferenceJoined = async (participant) => {
   console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
   const data = await this.getParticipants();
}

handleVideoConferenceLeft = () => {
   console.log("handleVideoConferenceLeft");
   this.router.navigate(['/thank-you']);
}

handleMuteStatus = (audio) => {
   console.log("handleMuteStatus", audio); // { muted: true }
}

handleVideoStatus = (video) => {
   console.log("handleVideoStatus", video); // { muted: true }
}

getParticipants() {
   return new Promise((resolve, reject) => {
       setTimeout(() => {
           resolve(this.api.getParticipantsInfo()); // get all participants
       }, 500)
   });
}

executeCommand(command: string) {
 this.api.executeCommand(command);
 if(command == 'hangup') { 
//     let callData = {    
//         "sender_userid": localStorage.getItem('userid'),
//         "socket_id":
//       };
 
//    this.WebSocketService.emit('endCall', callData);
this.closeDialog();
  // this.dialog.closeAll();
     // return;
 }

 if(command == 'toggleAudio') {
     this.isAudioMuted = !this.isAudioMuted;
 }

 if(command == 'toggleVideo') {
     this.isVideoMuted = !this.isVideoMuted;
 }
}
  }